import * as React from "react"
import { Link } from "gatsby"
import { gsap } from 'gsap'


function Header() {

  React.useEffect(() => {   
    window.addEventListener("scroll", listenToScroll);
    return () => 
       window.removeEventListener("scroll", listenToScroll); 
  }, [])

  React.useEffect(() => { 
    gsap.from('nav a', { 
      y: -20,
      autoAlpha: 0,
      duration: 1,
      ease: 'power2',
      stagger: 0.1,
      // delay: 1
    })
  }, [])

  let [ scrollTop, setScrollTop ] = React.useState(0)

  function listenToScroll(e) {
    setScrollTop(e.srcElement.scrollingElement.scrollTop);
  }

  const style = {
    height: "3.3em", 
    backgroundColor: "#f5f5f0f2", 
    borderBottom: "var(--border)",
    transition: "0.3s ease"
  }

  return (
    <header style={scrollTop > 40 ? style : {transition: "0.3s ease"}}>
      <nav>
        <Link to="/">
          <h1>Ryan Hunter</h1>
        </Link>
        <Link to="/projects">Projects</Link>
        <Link to="/info">Info / Contact</Link>
      </nav>
    </header>
  )
}

export default Header
